import axios from 'axios';
import { useCookies } from 'react-cookie';
class SettingsApi {
  url: string;
  source = axios.CancelToken.source();

  constructor() {
    // this.url = 'http://127.0.0.1:8000';
    //  this.url = "https://ifrs.opalcityadvisory.com/api/public"

    //add line
       this.url = "https://ifrs.zainrohn.com/public"
    const [cookies, _, removeCookie] = useCookies();

    // Add Auth header
    axios.interceptors.request.use((config) => {
      config.headers['Accept'] = 'application/json';
      config.headers['Content-Type'] = 'application/json';
      config.headers['X-Requested-With'] = 'XMLHttpRequest';
      config.cancelToken = this.source.token;
      if (cookies.userinfo) {
        config.headers['Authorization'] = cookies.userinfo.accessToken;
      }
      return config;
    });

    // Handle 401
    axios.interceptors.response.use(
      (response) => {
        if (!response) return Promise.reject(response);
        return response;
      },
      function (error) {
        if (!error) return Promise.reject(error);
        if (401 === error.response?.status) {
          removeCookie('userinfo');
          removeCookie('token');
        } else {
          return Promise.reject(error);
        }
      },
    );
  }
  /**
   * Settings APIs
   * @param {}
   */
  settings(): {
    general(): any;
    showSettingsModels(): any;
    activeSettingModels(query: { id: any }): any;
    addSettingModels(query: any): any;
    saveGeneral(query: { id: number; value: string | number }): Promise<any>;
    documents(query: { page: number; page_size: number }): any;

    documents1(query: { page: number; page_size: number }): any;
    documents2(query: { page: number; page_size: number }): any;
    documents(query: { page: number; page_size: number }): any;

    saveDocuments(query: { id: number; ccf: string | number }): Promise<any>;
    saveDocuments1(query: { id: number; score: string | number }): Promise<any>;
    saveDocuments2(query: { id: number; score: string | number }): Promise<any>;
    saveDocuments3(query: {
      name: string | number;
      index: string | null;
      category: string | null;
      sub_category: string | null;
    }): Promise<any>;

    predefined(query: { page: number; page_size: number }): any;
    savePredefined(query: {
      id: number;
      pd: string | number;
      lgd: string | number;
    }): Promise<any>;
    lgd(query: { page: number; page_size: number }): any;
    saveLgd(query: { id: number; value: string | number }): Promise<any>;
  } {
    var endpoints: any = {};

    endpoints.general = (query: any, name = 'settings/get-values') =>
      axios.get(`${this.url}/${name}`, { params: query });
    endpoints.showSettingsModels = (
      query: any,
      name = 'settings/show-settings-models',
    ) => axios.get(`${this.url}/${name}`, { params: query });
    endpoints.activeSettingModels = (
      query: any,
      name = 'settings/active-setting-model',
    ) => axios.post(`${this.url}/${name}`, query);
    endpoints.addSettingModels = (
      query: any,
      name = 'settings/add-setting-model',
    ) => axios.post(`${this.url}/${name}`, query);
    endpoints.saveGeneral = (query: any, name = 'settings/update-value') =>
      axios.post(`${this.url}/${name}/${query.id}/${query.value}`);

    endpoints.documents = (query: any, name = 'settings/show-document-types') =>
      axios.get(`${this.url}/${name}`, { params: query });

    endpoints.documents1 = (
      query: any,
      name = 'settings/show-document-types1',
    ) => axios.get(`${this.url}/${name}`, { params: query });
    endpoints.documents2 = (
      query: any,
      name = 'settings/show-document-types2',
    ) => axios.get(`${this.url}/${name}`, { params: query });

    endpoints.saveDocuments = (
      query: any,
      name = 'settings/update-document-type',
    ) => axios.post(`${this.url}/${name}/${query.id}`, { ccf: query.ccf });

    endpoints.saveDocuments1 = (
      query: any,
      name = 'settings/update-document-type1',
    ) => axios.post(`${this.url}/${name}/${query.id}`, { score: query.score });

    endpoints.saveDocuments2 = (
      query: any,
      name = 'settings/update-document-type2',
    ) => axios.post(`${this.url}/${name}/${query.id}`, { score: query.score });

    endpoints.saveDocuments3 = (
      query: any,
      name = 'settings/update-document-type3',
    ) =>
      axios.post(`${this.url}/${name}`, {
        name: query.name,
        index: query.index,
        category: query.category,
        sub_category: query.sub_category,
      });

    endpoints.predefined = (query: any, name = 'settings/show-predefined') =>
      axios.get(`${this.url}/${name}`, { params: query });

    endpoints.savePredefined = (
      query: any,
      name = 'settings/update-predefined',
    ) =>
      axios.post(`${this.url}/${name}/${query.id}`, {
        pd: query.pd,
        lgd: query.lgd,
      });

    endpoints.lgd = (query: any, name = 'settings/show-guarantee-lgd') =>
      axios.get(`${this.url}/${name}`, { params: query });

    endpoints.saveLgd = (query: any, name = 'settings/update-guarantee-lgd') =>
      axios.post(`${this.url}/${name}/${query.id}`, { value: query.value });

    return endpoints;
  }

  abortCalls = () =>
    this.source.cancel('Operation canceled due to route change.');
}

export default SettingsApi;
