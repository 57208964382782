import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-multi-lang';
import {
  InputField,
  SelectField,
  Textarea,
} from '../../../components/FormElements/FormElements';
import { WhiteboxLoader } from '../../../components/Loader/Loader';

import Search from '../../../assets/images/vectors/search.svg';
import DetailsModal from '../../../components/DetailsModal/DetailsModal';
import { Col, Row } from 'react-grid-system';

import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ClientProfile from './ClientProfle/ClientProfile';
import ClientStage from './StagingProfle/ClientStage';
import Modal from '../../../components/Modal/Modal';
import { TYPES, LIMITS } from '../../../action/search';
import {
  getFileName,
  getPercentage,
  numberWithCommas,
  toFixed,
} from '../../../services/hoc/helpers';
import { FinancialStatusMenu } from '../../../components/PredefinedMenus/PredefinedMenus';
import ImportAttachments from './ImportAttachments/ImportAttachments';
import { Value } from 'sass';
import { ClassificationType } from 'typescript';
import ClientApi from '../../../services/api/clients';
//import  {years1}  from '../../../services/hoc/helpers'

export default () => {
  // Force stage
  const [showForceStage, setShowForceStage] = useState(false);
  const [stage, setStage] = useState<number>(0);
  const [loadingStage, setLoadingStage] = useState(false);

  // Force grade
  const [showForceGrade, setShowForceGrade] = useState(false);
  const [grade, setGrade] = useState<number>(0);
  const [loadingGrade, setLoadingGrade] = useState(false);
  // History
  const history = useHistory();
  const location = useLocation();
  // Hooks
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [cif, setCIF] = useState<string | null>(null);
  const [client, setClient] = useState<any>(null);

  const [Grade_y_q, setGrade_y_q] = useState<number>(0);

  const [active_account, setActiveAccount] = useState<number>(0);
  const [showLimits, setShowLimits] = useState<
    'direct_limit_account' | 'undirect_limit_account' | false
  >(false);
  const [financialStatus, setFinancialStatus] = useState<string>('');
  const [showImportAttachments, setShowImportAttachments] =
    useState<boolean>(false);

  // Testing Years & Quarters
  const [year, setYear] = useState<number>();
  const [quarter, setQuarter] = useState<'q1' | 'q2' | 'q3' | 'q4'>();

  const [count, setCount] = useState<number>();

  // Years & Quarters
  const [years, setYears] = useState<any>({});
  const [selectedYear, setSelectedYear] = useState<any>();
  const [selectedQuarter, setSelectedQuarter] = useState<any>();
  // const [activeAccountInfo, setActiveAcountInfo] = useState<any>()

  // Client type
  const [clientType, setClientType] = useState<any>(TYPES[0]);
  const [limits, setLimits] = useState<any>(LIMITS[1]);

  const [isOpenEditRate, setIsOpenEditRate] = useState<boolean>(false);
  const [isOpenEditStage, setIsOpenEditStage] = useState<boolean>(false);
  const [isOpenECL, setIsOpenECL] = useState<boolean>(false);

  const [moreECLDetails, setMoreECLDetails] = useState<boolean>(true);

  const [isUpdate, setisUpdate] = useState<boolean>(false);

  // Translation
  const t = useTranslation();
  // API
  const ENDPOINTS = new ClientApi();
  const search = (
    e?: React.FormEvent<HTMLFormElement>,
    customCIF?: string,
    year?: string,
    quarter?: string,
  ) => {
    e?.preventDefault();
    // let select_type = selectedAccountType;
    let search_cif = cif || customCIF;

    if (!search_cif) return;
    // if (!select_type) return;
    setIsLoading(true);

    const params = new URLSearchParams(location.search);

    params.set('cif', String(search_cif == '0' ? '00000' : search_cif));

    window.history.replaceState({}, '', `${location.pathname}?${params}`);

    const query = new URLSearchParams(location.search);

    const type: any = query.get('client_type');
    const limitsParam: any = query.get('limits');
    const query_year = year || query.get('year');
    const query_quarter = quarter || query.get('quarter');

    if (limitsParam) {
      setShowLimits('direct_limit_account');
    }

    ENDPOINTS.clients()
      .search_cif({
        cif: search_cif == '0' ? '00000' : search_cif,
        balance: type === 'offbalance' ? 'off' : 'on',
        limit: limitsParam,
        year: query_year ? +query_year : undefined,
        quarter: query_quarter || undefined,
      })
      .then((response: any) => {
        setIsLoading(false);
        if (response.data.data === null) {
          toast("We couldn't find a client with this CIF.", {
            progressStyle: { background: 'tomato' },
          });
          return;
        }
        // If the response is object then convert it to an array
        if (response.data.data?.client_accounts instanceof Object) {
          response.data.data.client_accounts = Object.values(
            response.data.data?.client_accounts,
          );
        }
        setClient(response.data.data);

        if (
          response.data.data.class_type_id == 4 ||
          response.data.data.class_type_id == 1 ||
          response.data.data.class_type_id == 2 ||
          response.data.data.class_type_id == 3
        ) {
          //setGrade_y_q(response.data.data.final_grade);
          let yearsToSave: any = {};
          setGrade_y_q(
            response.data.data.client_accounts[active_account]?.account_infos[0]
              ?.final_grade,
          );

          if (!query_year && !query_quarter) {
            setSelectedYear({
              label: response.data.data?.year,
              value: response.data.data?.year,
            });

            setSelectedQuarter({
              label: response.data.data?.quarter,
              value: response.data.data?.quarter,
            });

            yearsToSave[response.data.data?.year]?.quarters?.push(
              response.data.data?.quarter,
            );
          }
        }

        setFinancialStatus(response.data?.data?.financial_status);
        setShowDetails(true);
        let yearsToSave: any = {};
        response.data.data?.filter?.map((record: any) => {
          if (!yearsToSave[record.year])
            yearsToSave[record.year] = {
              label: record.year,
              value: record.year,
              quarters: [],
            };
          yearsToSave[record.year]?.quarters?.push(record.quarter);
        });

        if (query_year && query_quarter) {
          setSelectedYear({ label: query_year, value: query_year });
          setYears(yearsToSave);

          const firstQuarter = query_quarter;
          setSelectedQuarter({ label: firstQuarter, value: firstQuarter });
        } else {
          setYears(yearsToSave);

          if (!selectedYear)
            setSelectedYear(yearsToSave[Object.keys(yearsToSave)[0]]);

          if (!selectedQuarter) {
            const firstQuarter =
              yearsToSave[Object.keys(yearsToSave)[0]]?.quarters[0];
            setSelectedQuarter({ label: firstQuarter, value: firstQuarter });
          }
        }
      })
      .catch(() => {
        setIsLoading(false);
        toast("We couldn't find a client with this CIF.", {
          progressStyle: { background: 'tomato' },
        });
      });
  };
  const ChnageOption = (value: any) => {
    console.log(value);
    setSelectedAccountType(value);
  };
  useEffect(() => {
    setSelectedAccountType('');
    const query = new URLSearchParams(location.search);
    const query_cif = query.get('cif');
    if (query_cif && !isLoading && !showDetails) {
      setCIF(query_cif);

      search(undefined, query_cif);
    } else if (!query_cif) {
      setCIF(null);
      setShowDetails(false);
    }

    const type = query.get('client_type');
    if (type) {
      setClientType(TYPES.find((item) => item.value === type) || clientType);
    }
    const limitsParam = query.get('limits');
    if (limitsParam) {
      setLimits(LIMITS.find((item) => item.value === limitsParam) || limits);
    }
  }, [location]);

  const changeYear = (selected: any) => {
    setSelectedYear(selected);
    setSelectedQuarter({
      label: years[selected.value].quarters[0],
      value: years[selected.value].quarters[0],
    });
    // setActiveAcountInfo(years[selected.value].quarters[0].value)
    const params = new URLSearchParams(location.search);
    params.set('year', String(selected.value));
    params.set('quarter', String(years[selected.value].quarters[0]));
    window.history.replaceState({}, '', `${location.pathname}?${params}`);
    search(
      undefined,
      undefined,
      selected.value,
      years[selected.value].quarters[0],
    );
    setIsLoading(true);
  };

  const changeQuarter = (selected: any) => {
    setSelectedQuarter(selected);
    // setActiveAcountInfo(selected.value)
    const params = new URLSearchParams(location.search);
    params.set('year', String(selectedYear?.value));
    params.set('quarter', String(selected.value));
    window.history.replaceState({}, '', `${location.pathname}?${params}`);
    search(undefined, undefined, selectedYear?.value, selected.value);
    setIsLoading(true);
  };

  const changeClientType = (selected: any) => {
    setClientType(selected);
    const params = new URLSearchParams(location.search);
    params.set('client_type', String(selected.value));
    window.history.replaceState({}, '', `${location.pathname}?${params}`);
    window.location.reload();
  };

  const changeLimits = (selected: any) => {
    setLimits(selected);
    const params = new URLSearchParams(location.search);
    if (selected?.value) params.set('limits', String(selected.value));
    else params.delete('limits');
    window.history.replaceState({}, '', `${location.pathname}?${params}`);
    window.location.reload();
  };
  // const sleep = (ms: any) => new Promise((r) => setTimeout(r, ms));

  /// drop down for account type search
  const [selectedAccountType, setSelectedAccountType] = useState('');

  const accountTypeOptions = [
    { value: 'onbalance', label: 'On Balance' },
    { value: 'offbalance', label: 'Off Balance' },
    { value: 'limit', label: 'Limit' },
  ];
  const handleSearch = () => {
    if (cif && selectedAccountType) {
      if (selectedAccountType != 'limit') {
        history.push(
          `/search-client?cif=${cif}&client_type=${selectedAccountType}`,
        );
      } else {
        history.push(
          `/search-client?cif=${cif}&limits=yes&client_type=offbalance`,
        );
      }
    } else {
      toast('please select cif and account type.', {
        progressStyle: { background: 'tomato' },
        autoClose: 1300,

        onClose: function () {
          window.location.reload();
        },
      });
    }
  };
  
  return (
    <div className="search-client">
      <DetailsModal data={{}} isOpen={false} toggle={() => {}} />

      {showDetails ? (
        <>
          {isLoading ? <WhiteboxLoader /> : ''}
          <div className="search-client-actions">
            <Row>
              <Col md={12}>
                <Row>
                  <Col
                    md={3}
                    component="form"
                    onSubmit={(e) => e.preventDefault()}>
                    <InputField
                      onKeyPress={(
                        e: React.KeyboardEvent<HTMLInputElement>,
                      ) => {
                        e.stopPropagation();
                        if (e.key === 'Enter') search();
                      }}
                      style={{ background: '#FFF', border: '1px solid #DDD' }}
                      value={cif == '0' ? '00000' : cif}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setCIF(e.target.value);
                      }}
                      placeholder={t('client_cif')}
                      //disabled
                    />
                  </Col>
                  <Col
                    md={1.5}
                    component="form"
                    onSubmit={(e) => e.preventDefault()}>
                    <SelectField
                      placeholder="Year"
                      options={Object.values(years)}
                      value={selectedYear}
                      onChange={(selected: any) => changeYear(selected)}
                    />
                  </Col>
                  <Col
                    md={1.5}
                    component="form"
                    onSubmit={(e) => e.preventDefault()}>
                    <SelectField
                      placeholder="Quarter"
                      options={years[selectedYear?.value]?.quarters.map(
                        (q: string) => ({ label: q, value: q }),
                      )}
                      value={selectedQuarter}
                      onChange={(selected: any) => changeQuarter(selected)}
                    />
                  </Col>

                  <Col
                    md={4}
                    style={{
                      position: 'relative',
                      top: 11,
                      textAlign: 'right',
                    }}
                    className="actions">
                    <button
                      className="button color-gold"
                      onClick={() => {
                        setIsOpenEditStage(true);
                        setSelectedYear(selectedYear);
                        setSelectedQuarter(selectedQuarter);
                      }}
                      disabled={
                        client.class_type_name == 'Retail' ||
                        client.class_type_name == 'Local Bank' ||
                        client.class_type_name == 'Abroad Bank' ||
                        client.class_type_name == 'Investments' ||
                        client.class_type_name == 'Central Bank'
                      }>
                      {t('client_stage')}
                    </button>
                    <span className="margin-10" />
                    <button
                      className="button bg-gold color-white"
                      onClick={() => {
                        setIsOpenEditRate(true);
                        setSelectedYear(selectedYear);
                        setSelectedQuarter(selectedQuarter);
                      }}
                      disabled={
                        client.class_type_name == 'Retail' ||
                        client.class_type_name == 'Local Bank' ||
                        client.class_type_name == 'Abroad Bank' ||
                        client.class_type_name == 'Investments' ||
                        client.class_type_name == 'Central Bank'
                          ? true
                          : false
                      }>
                      {t('client_rate')}
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <table
            className="details-table margin-top-50"
            style={{ width: '100%', overflow: 'visible' }}>
            <tbody>
              <tr>
                <td>{t('cif')}</td>
                <td>{client.cif}</td>
                <td>{t('name')}</td>
                <td>{client.name}</td>
                <td>{t('branch')}</td>
                <td>{client.branch_name}</td>
              </tr>
              <tr>
                <td>{t('class_type')}</td>
                <td>{client.class_type_name}</td>

                <td style={{ background: '#2595f6', color: '#FFF' }}>
                  Total EAD
                </td>

                {client.direct_limit_account ? (
                  <td style={{ background: '#0595f6', color: '#FFF' }}>
                    {numberWithCommas(
                      toFixed(
                        client?.direct_limit_account?.ead +
                          client?.undirect_limit_account?.ead,
                        2,
                      ),
                    )}
                  </td>
                ) : (
                  <td style={{ background: '#0091f9', color: '#FFF' }}>
                    {numberWithCommas(
                      toFixed(
                        client?.client_accounts
                          ?.map((account: any) =>
                            Number(account?.account_infos[0]?.ead || 0),
                          )
                          .reduce((a: number, b: number) => a + b, 0) +
                          (showLimits ? client.limit_account?.ead || 0 : 0),
                        2,
                      ),
                    )}
                  </td>
                )}

                <td style={{ background: '#1abc62', color: '#FFF' }}>
                  {' '}
                  Total ECL
                </td>
                {client.direct_limit_account ? (
                  <td style={{ background: '#17a656', color: '#FFF' }}>
                    {numberWithCommas(
                      toFixed(
                        client?.direct_limit_account?.ecl +
                          client?.undirect_limit_account?.ecl,
                        2,
                      ),
                    )}
                  </td>
                ) : (
                  <td style={{ background: '#17a656', color: '#FFF' }}>
                    {numberWithCommas(
                      toFixed(
                        client?.client_accounts
                          ?.map((account: any) =>
                            Number(account?.account_infos[0]?.ecl || 0),
                          )
                          .reduce((a: number, b: number) => a + b, 0) +
                          (showLimits ? client.limit_account?.ecl || 0 : 0),
                        2,
                      ),
                    )}
                  </td>
                )}
              </tr>
            </tbody>
          </table>

          {/* {client.direct_limit_account && (
            <>
              <h2>Limits</h2>
              <table
                className="details-table margin-top-30"
                style={{ width: '100%', overflow: 'visible' }}>
                <tbody>
                  <tr>
                    <td>{t('direct_limit')}</td>
                    <td>{numberWithCommas(client.direct_limit)}</td>
                    <td>{t('unused_direct_limit')}</td>
                    <td>{numberWithCommas(client.unused_direct_limit)}</td>
                    <td>{t('used_direct_limit')}</td>
                    <td>{numberWithCommas(client.used_direct_limit)}</td>
                  </tr>
                  <tr>
                    <td>{t('indirect_limit')}</td>
                    <td>{numberWithCommas(client.un_direct_limit)}</td>
                    <td>{t('unused_indirect_limit')}</td>
                    <td>{numberWithCommas(client.unused_undirect_limit)}</td>
                    <td>{t('used_indirect_limit')}</td>
                    <td>{numberWithCommas(client.used_un_direct_limit)}</td>
                  </tr>
                </tbody>
              </table>
            </>
          )} */}
          <ul className="tabs">
            {!!client[showLimits || ''] && (
              <li
                className={
                  showLimits === 'direct_limit_account' ? 'active' : ''
                }
                onClick={() => setShowLimits('direct_limit_account')}>
                {' '}
                Undirect limits account
              </li>
            )}

            {!!client.undirect_limit_account && (
              <li
                className={
                  showLimits === 'undirect_limit_account' ? 'active' : ''
                }
                onClick={() => {
                  setShowLimits('undirect_limit_account');
                }}>
                {' '}
                Direct limits account
              </li>
            )}
            {!client.direct_limit_account && (
              <>
                {client.client_accounts?.map((account: any, index: number) => (
                  <li
                    className={
                      active_account === index && !showLimits ? 'active' : ''
                    }
                    onClick={() => {
                      setActiveAccount(index);
                      setShowLimits(false);
                    }}>
                    {t('account')}: {account.loan_key} / {account.type_name}
                  </li>
                ))}
              </>
            )}
          </ul>
          {showLimits ? (
            <>
              {/* Limits account */}
              <table
                className="details-table margin-top-50"
                style={{ width: '100%', overflow: 'visible' }}>
                <tbody>
                  <tr>
                    <td>Stage</td>
                    <td>
                      <div
                        className="force"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                        }}>
                        {client?.stage_no}
                      </div>
                    </td>
                    <td>Grade</td>
                    <td>
                      <div
                        className="force"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                        }}>
                        {client?.final_grade}
                      </div>
                    </td>
                    <td>PD</td>
                    <td>{getPercentage(client[showLimits || '']?.pd)}</td>
                  </tr>
                  <tr>
                    <td>LGD</td>
                    <td>
                      {client[showLimits || '']?.final_lgd
                        ? getPercentage(client[showLimits || '']?.final_lgd)
                        : 'N/A'}
                    </td>
                    <td>EAD</td>
                    <td>
                      {client[showLimits || '']?.ead
                        ? numberWithCommas(
                            toFixed(Number(client[showLimits || '']?.ead), 2),
                          )
                        : '0'}
                    </td>
                    <td style={{ background: '#3595f6', color: '#FFF' }}>
                      Account ECL
                    </td>
                    <td style={{ background: '#2478ce', color: '#FFF' }}>
                      {numberWithCommas(
                        toFixed(Number(client[showLimits || '']?.ecl), 2),
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <button
                className="button color-gold margin-top-30"
                onClick={() => setIsOpenECL(true)}>
                Show ECL Details
              </button>
              <br />
              <br />
              <table
                className="details-table margin-top-30"
                style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td>{t('facility_type')}</td>
                    <td>
                      {showLimits === 'direct_limit_account'
                        ? 'Undirect Limit'
                        : 'Direct Limit'}
                    </td>
                    <td>{t('guarantee_ccy')}</td>
                    <td>
                      {showLimits === 'direct_limit_account'
                        ? client.undirect_limit_account.guarantee_currency
                        : client.direct_limit_account.guarantee_currency}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('currency_type')}</td>
                    <td>
                      {showLimits === 'direct_limit_account'
                        ? client.undirect_currency_type
                        : client.direct_currency_type}
                    </td>
                    <td>{t('cm_guarantee')}</td>
                    <td>
                      {showLimits === 'direct_limit_account'
                        ? client.undirect_limit_account.cm_guarantee
                        : client.direct_limit_account.cm_guarantee}
                    </td>
                  </tr>
                  <tr>
                    <td>Limit CCY </td>
                    <td>
                      {showLimits === 'direct_limit_account'
                        ? toFixed(Number(client.un_direct_limit), 2)
                        : toFixed(Number(client.direct_limit), 2)}
                    </td>
                    <td>Rest Of Guar Amount CM</td>
                    <td>
                      {showLimits === 'direct_limit_account'
                        ? client.undirect_limit_account.pv_cm_guarantees
                        : client.direct_limit_account.pv_cm_guarantees}
                    </td>
                  </tr>
                  <tr>
                    <td>Used Limit</td>
                    <td>
                      {showLimits === 'direct_limit_account'
                        ? toFixed(Number(client.used_un_direct_limit), 2)
                        : toFixed(Number(client.used_direct_limit), 2)}
                    </td>
                    <td>Accepted value of stocks collateral</td>
                    <td>
                      {toFixed(
                        Number(client.Accepted_value_of_stocks_collateral),
                        2,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Unused Limit</td>
                    <td>
                      {showLimits === 'direct_limit_account'
                        ? toFixed(Number(client.unused_undirect_limit), 2)
                        : toFixed(Number(client.unused_direct_limit), 2)}
                    </td>
                    <td>Rest Of PV Guar Amount Sec</td>
                    <td>
                      {showLimits === 'direct_limit_account'
                        ? client.undirect_limit_account.pv_securities_guarantees
                        : client.direct_limit_account.pv_securities_guarantees}
                    </td>
                  </tr>
                  <tr>
                    <td>Cancellable WithOut Prior notice</td>
                    <td>{client.cancellable}</td>
                    <td>{t('pv_re_guarantees')}</td>
                    <td>
                      {toFixed(
                        Number(client.Accepted_value_of_real_estate_collateral),
                        2,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('document_type_ccf')}</td>
                    <td>{getPercentage(client.ccf)}</td>
                    <td>Rest Of PV Guar. Amount RE</td>
                    <td>
                      {showLimits === 'direct_limit_account'
                        ? toFixed(
                            Number(
                              client.undirect_limit_account.pv_re_guarantees,
                            ),
                            2,
                          )
                        : toFixed(
                            Number(
                              client.direct_limit_account.pv_re_guarantees,
                            ),
                            2,
                          )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <>
              {/* Normal Accounts */}
              <table
                className="details-table margin-top-50"
                style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td>Stage</td>
                    <td>
                      <div
                        className="force"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                        }}>
                        {
                          client.client_accounts[active_account]
                            ?.account_infos[0]?.stage_no
                        }
                      </div>
                    </td>
                    <td>Grade</td>
                    <td>
                      <div
                        className="force"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                        }}>
                        {Grade_y_q
                          ? client.client_accounts[active_account]
                              ?.account_infos[0]?.final_grade
                          : client.client_accounts[active_account]
                              ?.account_infos[0]?.interInternal_rate}
                      </div>
                    </td>
                    <td>PD</td>
                    <td>
                      {getPercentage(
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.pd,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>LGD</td>
                    <td>
                      {client.client_accounts[active_account]?.account_infos[0]
                        ?.final_lgd
                        ? getPercentage(
                            client.client_accounts[active_account]
                              ?.account_infos[0]?.final_lgd,
                          )
                        : 'N/A'}
                    </td>
                    <td> EAD</td>
                    <td>
                      {client.client_accounts[active_account]?.account_infos[0]
                        ?.ead
                        ? numberWithCommas(
                            toFixed(
                              Number(
                                client.client_accounts[active_account]
                                  ?.account_infos[0]?.ead,
                              ),
                              2,
                            ),
                          )
                        : '0'}
                    </td>
                    <td style={{ background: '#3595f6', color: '#FFF' }}>
                      Account ECL
                    </td>
                    <td style={{ background: '#2478ce', color: '#FFF' }}>
                      {numberWithCommas(
                        toFixed(
                          Number(
                            client.client_accounts[active_account]
                              ?.account_infos[0]?.ecl,
                          ),
                          2,
                        ),
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <button
                className="button color-gold margin-top-30"
                onClick={() => setIsOpenECL(true)}>
                Show ECL Details
              </button>

              <table
                className="details-table margin-top-30"
                style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td>{t('facility_type')}</td>
                    <td>{client.client_accounts[active_account]?.type_name}</td>
                    <td>{t('number_of_reschedule')}</td>
                    <td>
                      {
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.number_of_reschedule
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>{t('currency_type')}</td>
                    <td>
                      {client.client_accounts[active_account]?.currency_name}
                    </td>
                    <td>{t('guarantee_ccy')}</td>
                    <td>
                      {client.client_accounts[active_account]?.gu_currency_name}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('outstanding_fcy')}</td>
                    <td>
                      {numberWithCommas(
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.outstanding_fcy,
                      ) ===
                      numberWithCommas(
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.outstanding_lcy,
                      )
                        ? '-'
                        : numberWithCommas(
                            client.client_accounts[active_account]
                              ?.account_infos[0]?.outstanding_fcy,
                          )}
                    </td>
                    <td>{t('cm_guarantee')}</td>
                    <td>
                      {numberWithCommas(
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.cm_guarantee,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('outstanding_lcy')}</td>
                    <td>
                      {numberWithCommas(
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.outstanding_lcy,
                      )}
                    </td>
                    <td>{t('estimated_value_of_stock_collateral')}</td>
                    <td>
                      {numberWithCommas(
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.estimated_value_of_stock_collateral,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('accrued_interest_lcy')}</td>
                    <td>
                      {numberWithCommas(
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.accrued_interest_lcy,
                      )}
                    </td>
                    <td>{t('pv_securities_guarantees')}</td>
                    <td>
                      {numberWithCommas(
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.pv_securities_guarantees,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('suspended_lcy')}</td>
                    <td>
                      {numberWithCommas(
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.suspended_lcy,
                      )}
                    </td>
                    <td>{t('mortgages')}</td>
                    <td>
                      {numberWithCommas(
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.mortgages,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('interest_received_in_advance_lcy')}</td>
                    <td>
                      {
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.interest_received_in_advance_lcy
                      }
                    </td>
                    <td>{t('estimated_value_of_real_estate_collateral')}</td>
                    <td>
                      {numberWithCommas(
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.estimated_value_of_real_estate_collateral,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('st_date')}</td>
                    <td>
                      {client.client_accounts[active_account].account_infos[0]
                        .st_date === '1970-01-01'
                        ? ' '
                        : client.client_accounts[active_account]
                            ?.account_infos[0]?.st_date}
                    </td>
                    <td>
                      {t('80_per_estimated_value_of_real_estate_collateral')}
                    </td>
                    <td>
                      {numberWithCommas(
                        client.client_accounts[active_account]
                          ?.account_infos[0]?.[
                          '80_per_estimated_value_of_real_estate_collateral'
                        ],
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('mat_date')}</td>
                    <td>
                      {
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.mat_date
                      }
                    </td>
                    <td>{t('pv_re_guarantees')}</td>
                    <td>
                      {numberWithCommas(
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.pv_re_guarantees,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('sp_date')}</td>
                    <td>
                      {
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.sp_date
                      }
                    </td>
                    <td>{t('interest_rate')}</td>
                    <td>
                      {getPercentage(
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.interest_rate,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{t('past_due_days')}</td>
                    <td>
                      {
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.past_due_days
                      }
                    </td>
                    <td>{t('pay_method')}</td>
                    <td>
                      {
                        client.client_accounts[active_account]?.account_infos[0]
                          ?.pay_method
                      }
                    </td>
                  </tr>
                  {client.client_accounts[active_account]?.account_infos[0]
                    ?.account?.document_type && (
                    <tr>
                      <td>{t('document_type')}</td>
                      <td>
                        {
                          client.client_accounts[active_account]
                            ?.account_infos[0]?.account?.document_type?.name
                        }
                      </td>
                      <td>{t('document_type_ccf')}</td>
                      <td>
                        {getPercentage(
                          client.client_accounts[active_account]
                            ?.account_infos[0]?.account?.document_type?.ccf,
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          )}

          <br />
          <br />

          <div className="client-attachments">
            <label>Attachments</label>
            <div className="attachments">
              <ul>
                {client.attachments?.map((attachment: any) => (
                  <li>
                    <a href={attachment?.path}>
                      <i className="icon-attachment"></i>
                      {getFileName(attachment?.path)}
                    </a>
                  </li>
                ))}
                {client.attachments?.length === 0 && (
                  <li className="text-center" style={{ padding: 20 }}>
                    No attachments
                  </li>
                )}
              </ul>
              <div className="margin-top-20">
                <button
                  className="button bg-gold color-white"
                  onClick={() => setShowImportAttachments(true)}>
                  Add attachments
                </button>
              </div>
            </div>
          </div>
          <br />
          <br />
          {/* Import Attachments */}
          <ImportAttachments
            open={showImportAttachments}
            toggle={() => setShowImportAttachments(false)}
            client_id={client.id}
          />
          {/* Client Rate */}
          <ClientProfile
            isOpen={isOpenEditRate}
            toggle={() => setIsOpenEditRate(false)}
            client_id={client.id}
            year={selectedYear?.value}
            quarter={selectedQuarter?.value}
            class_type={client.class_type_id}
            financial_status={financialStatus}
            changeFinancialStatus={setFinancialStatus}
            isUpdate={isUpdate}
          />

          {/* Client Stage */}
          <ClientStage
            isOpen={isOpenEditStage}
            toggle={() => setIsOpenEditStage(false)}
            client_id={client.id}
            year={selectedYear?.value}
            quarter={selectedQuarter?.value}
            class_type={client.class_type_id}
            isUpdate={isUpdate}
          />
          <Modal open={isOpenECL} toggle={() => setIsOpenECL(false)}>
            {/* <div className="text-right">
              <button
                className={
                  moreECLDetails
                    ? 'button color-white bg-gold'
                    : 'button color-gold'
                }
                onClick={() => setMoreECLDetails(!moreECLDetails)}>
                {moreECLDetails ? 'Less Details' : 'More Details'}
              </button>
            </div> */}

            <div className="margin-top-10" />
            <table
              className="table margin-top-30"
              style={{ width: '90vw', marginBottom: '10' }}>
              <thead>
                <tr>
                  <th>Valuation Date</th>
                  <th>Expiry Date</th>
                  <th>Payment Frequency</th>
                  <th>Starting Exposure</th>
                  <th>PD 12 m</th>
                  <th>Discount Rate 12 M</th>
                  <th>LGD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {
                      client.client_accounts[active_account]?.account_infos[0]
                        ?.valuationDate
                    }
                  </td>
                  <td>
                    {
                      client.client_accounts[active_account]?.account_infos[0]
                        ?.mat_date
                    }
                  </td>
                  <td>
                    {numberWithCommas(
                      client.client_accounts[active_account]?.account_infos[0]
                        ?.number_of_installments,
                    )}
                  </td>
                  <td>
                    {client.client_accounts[active_account]?.account_infos[0]
                      ?.ead
                      ? numberWithCommas(
                          toFixed(
                            Number(
                              client.client_accounts[active_account]
                                ?.account_infos[0]?.ead,
                            ),
                            2,
                          ),
                        )
                      : '0'}
                  </td>
                  <td>
                    {getPercentage(
                      client.client_accounts[active_account]?.account_infos[0]
                        ?.pd,
                    )}
                  </td>
                  <td>
                    {getPercentage(
                      client.client_accounts[active_account]?.account_infos[0]
                        ?.discount_rate_12_m,
                    )}
                  </td>
                  <td>
                    {client.client_accounts[active_account]?.account_infos[0]
                      ?.final_lgd
                      ? getPercentage(
                          client.client_accounts[active_account]
                            ?.account_infos[0]?.final_lgd,
                        )
                      : '0'}
                  </td>
                </tr>
              </tbody>
            </table>
            <br></br>
            <table
              className="table margin-top-30"
              style={{ width: '90vw', margin: 0 }}>
              <thead>
                <tr>
                  <th>Repayment date</th>
                  {moreECLDetails && <th>Days Between</th>}
                  {moreECLDetails && <th>Repayment Indicator</th>}
                  <th>Repayment</th>
                  <th>EAD</th>
                  {moreECLDetails && <th>Days for Discounting</th>}
                  <th>PD cum</th>
                  <th>PD Marginal</th>
                  <th>LGD</th>
                  <th>Discount rate</th>
                  <th>EL</th>
                  <th>CUM EL</th>
                  {moreECLDetails && <th>12-M Selector</th>}
                  <th>12-M EL</th>
                </tr>
              </thead>
              <tbody>
                {(showLimits
                  ? client[showLimits || '']?.ecl_data
                  : client.client_accounts[active_account]?.account_infos[0]
                      ?.ecl_data
                )?.map((item: any) => (
                  <tr>
                    <td>{item.repayment_date}</td>
                    {moreECLDetails && <td>{item.days_between}</td>}
                    {moreECLDetails && <td>{item.repayment_indicator}</td>}
                    <td title={item.repayment}>
                      {numberWithCommas(toFixed(Number(item.repayment), 2))}
                    </td>
                    <td title={item.ead_end_of_period}>
                      {numberWithCommas(
                        toFixed(Number(item.ead_end_of_period), 2),
                      )}
                    </td>
                    {moreECLDetails && <td>{item.days_for_discount}</td>}
                    <td title={item.pd_cum}>{getPercentage(item.pd_cum)}</td>
                    <td title={item.pd_marginal}>
                      {getPercentage(item.pd_marginal)}
                    </td>
                    <td title={item.lgd}>{getPercentage(item.lgd)}</td>
                    <td title={item.discount_rate}>
                      {getPercentage(item.discount_rate)}
                    </td>
                    <td title={item.el}>
                      {numberWithCommas(toFixed(Number(item.el), 2))}
                    </td>
                    <td title={item.cum_el}>
                      {numberWithCommas(toFixed(Number(item.cum_el), 2))}
                    </td>
                    {moreECLDetails && (
                      <td>{toFixed(Number(item['12_m_selector']), 2)}</td>
                    )}
                    <td title={item['12_m_el']}>
                      {numberWithCommas(toFixed(Number(item['12_m_el']), 2))}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td
                    colSpan={10}
                    style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    Total :{' '}
                  </td>
                  <td style={{ fontWeight: 'bold' }}>
                    {numberWithCommas(
                      toFixed(
                        (showLimits
                          ? client[showLimits || '']?.ecl_data
                          : client.client_accounts[active_account]
                              ?.account_infos[0]?.ecl_data
                        )?.reduce(
                          (sum: number, item: any) => sum + Number(item.el),
                          0,
                        ),
                        2,
                      ),
                    )}
                  </td>
                  <td colSpan={2}></td>

                  <td style={{ fontWeight: 'bold' }}>
                    {numberWithCommas(
                      toFixed(
                        (showLimits
                          ? client[showLimits || '']?.ecl_data
                          : client.client_accounts[active_account]
                              ?.account_infos[0]?.ecl_data
                        )?.reduce(
                          (sum: number, item: any) =>
                            sum + Number(item['12_m_el']),
                          0,
                        ),
                        2,
                      ),
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Modal>
          <Modal open={showForceStage} toggle={() => setShowForceStage(false)}>
            {loadingStage && <WhiteboxLoader />}
            <div style={{ minWidth: 350 }}>
              <h2 style={{ marginTop: 0 }}>Force Stage</h2>
              <InputField
                defaultValue={
                  client.client_accounts[active_account]?.account_infos[0]
                    ?.stage_no
                }
                placeholder="Client Stage"
                onChange={(e: any) => setStage(+e.target.value)}
              />
              <button
                className="button color-white bg-gold"
                onClick={() => {
                  const stageToSave =
                    stage ||
                    client.client_accounts[active_account]?.account_infos[0]
                      ?.stage_no;

                  if (stageToSave) {
                    setLoadingStage(true);

                    ENDPOINTS.clients()
                      .setStage({ id: client.id, stage: stageToSave })
                      .then(() => {
                        setLoadingStage(false);
                        setShowForceStage(false);
                        search(undefined, cif || '');
                      });
                  }
                }}>
                Save Stage
              </button>
            </div>
          </Modal>
          <Modal open={showForceGrade} toggle={() => setShowForceGrade(false)}>
            {loadingGrade && <WhiteboxLoader />}
            <div style={{ minWidth: 350 }}>
              <h2 style={{ marginTop: 0 }}>Force Grade</h2>
              <InputField
                defaultValue={
                  Grade_y_q
                    ? Grade_y_q
                    : client.client_accounts[active_account]?.account_infos[0]
                        ?.final_grade

                  //Grade_y_q
                }
                placeholder="Client Grade"
                onChange={(e: any) => setGrade(e.target.value)}
              />
              <button
                className="button color-white bg-gold"
                onClick={() => {
                  const gradeToSave =
                    grade ||
                    client.client_accounts[active_account]?.account_infos[0]
                      ?.final_grade;
                  if (gradeToSave) {
                    setLoadingGrade(true);
                    ENDPOINTS.clients()
                      .setGrade({
                        id: client.id,
                        grade: gradeToSave,
                        year: selectedYear?.value,
                        quarter: selectedQuarter?.value,
                      })
                      .then(() => {
                        setLoadingGrade(false);
                        setShowForceGrade(false);
                        search(undefined, cif || '');
                      });
                  }
                }}>
                Save Grade
              </button>
            </div>
          </Modal>
        </>
      ) : (
        <>
          <form
            style={{
              maxWidth: 500,
              background: '#F9F9F9',
              padding: '100px 40px',
              borderRadius: 10,
              position: 'relative',
            }}
            onSubmit={search}>
            {isLoading ? <WhiteboxLoader /> : ''}
            <h1 className="text-center" style={{ margin: '0 0 40px' }}>
              {t('search_for_client')}
            </h1>
            <InputField
              value={cif}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCIF(e.target.value)
              }
              style={{ background: '#FFF', border: '1px solid #DDD' }}
              placeholder={t('client_cif')}
            />

            <select
              required
              style={{
                maxWidth: '800px',
                margin: 'auto',
                width: '100%',
                height: '70%',
                color: '#ccc',
                border: '1px solid #EEE',
                borderRadius: '8px',
                cursor: 'pointer',
                padding: '4%',
              }}
              onChange={(e: any) => ChnageOption(e.target.value)}>
              <option disabled selected value="">
                {' '}
                -- select Account Type --{' '}
              </option>

              <optgroup label="Account Type">
                {accountTypeOptions.map((item) => (
                  <option value={item.value}>{item.label}</option>
                ))}
              </optgroup>
            </select>

            <div className="text-center margin-top-40">
              <button
                onClick={handleSearch}
                className="button bg-gold color-white round"
                style={{ padding: '0 50px' }}>
                {t('search_client')}
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};
