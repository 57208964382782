import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-multi-lang';
import { EllipsisLoader } from '../../components/Loader/Loader';
import Modal from '../../components/Modal/Modal';
import { DashboardTable } from '../../components/Table/Table';
import ClientIrsApi from '../../services/api/clientIRS';
import AddSettings from './AddSettings';
import { useDispatch, useSelector } from 'react-redux';

import { Confirm } from '../../components/Alerts/Alerts';

interface IProps {
  isOpen: boolean;
  toggle: Function;
 
}

export default (props: IProps) => {
  // Translation
  const t = useTranslation();

  // Hooks
  const [classType, setClassType] = useState<number | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [showQuestions, setShowQuestions] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(true);

  const [isUpdate, setisUpdate] = useState<boolean>(false);

  const [answers, setAnswers] = useState<number[]>([]);

  const [profiles, setProfiles] = useState<any>();

  // API
  const ENDPOINTS = new ClientIrsApi();

  ///////

  // Delete
  const remove = (id: number) => {
    ENDPOINTS.irs_profile()
      .delete({ id })
      .then(() => {});
  };

  //

  useEffect(() => {
    if (!isLoaded) {
      // Fetch data
      // ENDPOINTS.irs_profile()
      //   .index({ page: 1, page_size: 1000 }, props.client_id, 2021, 'q1')
      //   .then((response: any) => {
      //     setProfiles(response.data.data);

      //     //alert(response.data.data.year+'');
      //     setIsLoaded(true);
      //   });
    }
  }, [isLoaded]);

  ///////////////////
  const getProfiles = () => {
    // alert(profiles[0].client_id+'1111');
    //alert(profiles[1].client_id);

    return profiles.reduce((profilesObject: any, profile: any) => {
      profilesObject[profile.id] = {
        //date: new Date(profile.created_at).toLocaleDateString(),class_type: profile.final_score,grade: profile.grade,past_due_days: profile.max,final_grade: profile.final_grade, financial_status: profile.financial_status, actions: <div className="show-on-hover"><i className="icon-info" onClick={() => {
        date: new Date(profile.created_at).toLocaleString('en-GB', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }),
        class_type: profile.final_score,
        grade: profile.grade,
        past_due_days: profile.max,
        final_grade: profile.final_grade,
        quarter: profile.quarter,
        year: profile.year,
        financial_status: profile.financial_status,
        actions: (
          <div className="show-on-hover">
            <i
              className="icon-edit"
              onClick={() => {
                setEditable(true);
                setisUpdate(true);
                setShowQuestions(true);
                setAnswers(
                  profile.answers.map((answer: any) => answer.option_id),
                );
              }}
            />

            <i
              className="icon-delete"
              onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                e.stopPropagation();
                Confirm({
                  message: t('delete_confirmation'),
                  onConfirm: () => {
                    remove(profile.id);

                    // if (props.isOpen) {
                    //   ENDPOINTS.irs_profile()
                    //     .index(
                    //       { page: 1, page_size: 1000 },
                    //       props.client_id,
                    //       props.year,
                    //       props.quarter,
                    //     )
                    //     .then((response: any) => {
                    //       //alert(props.year+'');
                    //       setProfiles(response.data.data);

                    //       setIsLoaded(true);
                    //     });
                    // }
                  },
                });
              }}
            />
          </div>
        ),
      };

      // alert(profilesObject.financial_status);

      return profilesObject;
    }, {});
  };

  useEffect(() => {
    if (props.isOpen) {
      // ENDPOINTS.irs_profile()
      //   .index(
      //     { page: 1, page_size: 1000 },
      //     props.client_id,
      //     props.year,
      //     props.quarter,
      //   )
      //   .then((response: any) => {
      //     //alert(props.year+'');
      //     setProfiles(response.data.data);

      //     setIsLoaded(true);
      //   });
    }

    if (!props.isOpen) {
     
      setShowQuestions(false);
      setEditable(true);
      setAnswers([]);
    }
  }, [props.isOpen]);

  return (
    <Modal open={props.isOpen} toggle={props.toggle}>
      <AddSettings
  
        readonly={!editable}
        
    
        isUpdate={isUpdate}
      />
    </Modal>
  );
};
