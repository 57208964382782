import axios from 'axios';
import { useCookies } from 'react-cookie';

interface pagination {
  page: number;
  page_size: number;
  keyword?: string;
  class_type_id?: number;
  class_type_category?: string;
  quarter?: string;
  year?: number;
  type?: string;
  limit?: 'yes' | 'no';
  filter_type?: string;
}
class ClientApi {
  
  url: string;
  source = axios.CancelToken.source();

  constructor() {
    // this.url = "http://127.0.0.1:8000"
    //  this.url = "https://ifrs.opalcityadvisory.com/api/public"


    //add line
    this.url = "https://ifrs.zainrohn.com/public"

    const [cookies, _, removeCookie] = useCookies();
    

    // Add Auth header
    axios.interceptors.request.use((config) => {
      config.headers['Accept'] = 'application/json';
      config.headers['Content-Type'] = 'application/json';
      config.headers['X-Requested-With'] = 'XMLHttpRequest';
      config.cancelToken = this.source.token;
      if (cookies.userinfo) {
        config.headers['Authorization'] = cookies.userinfo.accessToken;
      }
      return config;
    });
    

    // Handle 401
    axios.interceptors.response.use(
      (response) => {
        if (!response) return Promise.reject(response);
        return response;
      },
      function (error) {
        if (!error) return Promise.reject(error);
        if (401 === error.response?.status) {
          removeCookie('userinfo');
          removeCookie('token');
        } else {
          return Promise.reject(error);
        }
      },
    );
  }
  /**
   * Clients APIs
   * @param {}
   */
 
  
  clients(): {
    index(query: pagination): any;
    show(query: { id: number }): any;
    search_cif(query: {
      cif: string;
      limit?: 'on' | 'off';
      balance?: string;
      year?: number;
      quarter?: string;
    }): any;
    store(query: {
      path: string;
      year: string;
      quarter: 'q1' | 'q2' | 'q3' | 'q4';
      type: string;
      replace?: boolean;
    }): any;
    import_limits(query: {
      path: string;
      year: string;
      quarter: 'q1' | 'q2' | 'q3' | 'q4';
      type: string;
      replace?: boolean;
    }): any;
    change_financial_status(query: {
      id: string[] | number;
      financial_status: string;
    }): any;
    add_attachments(query: { id: number; attachment_ids: number[] }): any;
    setStage(query: { id: number; stage: number }): any;
    setGrade(query: {
      id: number;
      grade: number;
      year: number;
      quarter: string;
    }): any;
  } {
    var endpoints: any = {};

    endpoints.index = (query: any, name = 'clients') =>
      axios.get(`${this.url}/${name}`, { params: query });

    endpoints.show = (query: any, name = 'clients') =>
      axios.get(`${this.url}/${name}/${query.id}`, { params: query });

    endpoints.search_cif = (query: any, name = 'clients') =>
      axios.get(`${this.url}/${name}/cif/${query.cif}`, { params: query });
    debugger;
    endpoints.store = (query: any, name = 'clients') =>
      axios.post(`${this.url}/${name}`, query);

    endpoints.import_limits = (query: any, name = 'limits/import') =>
      axios.post(`${this.url}/${name}`, query);

    endpoints.change_financial_status = (
      query: any,
      name = 'clients/change-financial-status',
    ) => axios.post(`${this.url}/${name}`, query);

    endpoints.add_attachments = (
      query: any,
      name = 'clients/add-attachments',
    ) => axios.post(`${this.url}/${name}/${query.id}`, query);

    endpoints.setStage = (query: any, name = 'clients/set-stage') =>
      axios.post(`${this.url}/${name}/${query.id}/${query.stage}`, query);

    endpoints.setGrade = (query: any, name = 'clients/set-grade') =>
      axios.post(
        `${this.url}/${name}/${query.id}/${query.grade}/${query.year}/${query.quarter}`,
        query,
      );
    return endpoints;
  }

  abortCalls = () =>
    this.source.cancel('Operation canceled due to route change.');
}

export default ClientApi;
