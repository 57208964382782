import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-multi-lang';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { StagingSlice, StagingState, staging } from './StagingSlice';

// API
import StagingApi from '../../services/api/staging';

// Components
import TableActionBar from '../../components/TableActionBar/TableActionBar';
import { DashboardTable } from '../../components/Table/Table';
import { EllipsisLoader, WhiteboxLoader } from '../../components/Loader/Loader';
import { ClassesMenu } from '../../components/PredefinedMenus/PredefinedMenus';
import { SelectField } from '../../components/FormElements/FormElements';

import { years } from '../../services/hoc/helpers';
import { Link } from 'react-router-dom';
//import AddRate from "../Clients/SearchClient/StagingProfle/AddStage"

import ClientStage_Groupe from '../Clients/SearchClient/StagingProfle/ClientStage_Groupe';
import { Col, Row } from 'react-grid-system';

export default () => {
  // Translation
  const t = useTranslation();

  // Redux
  const dispatch = useDispatch();
  const state = useSelector(
    (state: { staging: StagingState }) => state.staging,
  );

  // Hooks
  const [keyword, setKeyword] = useState<string>('');

  const [classType, setClassType] = useState<any>();
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [quarter, setQuarter] = useState<string>('q4');
  const [filter, setFilter] = useState<any>({
    label: 'No stage',
    value: 'without',
  });

  const [allchecked, setAllChecked] = React.useState<string[]>([]);
  const [isOpenEditStage, setIsOpenEditStage] = useState<boolean>(false);
  const [selectedYear, setSelectedYear] = useState<any>();
  const [selectedQuarter, setSelectedQuarter] = useState<any>();

  // API
  const ENDPOINTS = new StagingApi();

  // Table ref
  type TableHandle = React.ElementRef<typeof DashboardTable>;
  const tableRef = useRef<TableHandle>(null);

  // Search
  const search = (value: string) => {
    tableRef.current?.reset();
    dispatch(StagingSlice.actions.reset());
    setKeyword(value);
  };

  function handleChange(e: any) {
    //setAllChecked(e.target.checked);
    if (e.target.checked) {
      setAllChecked([...allchecked, e.target.value]);
    } else {
      setAllChecked(allchecked.filter((item: any) => item !== e.target.value));
    }

    if (allchecked) {
      //alert("The all checked values are"+ allchecked.join(" , "));
    }
  }

  // Fetch Data
  const fetchData = (page: number, page_size: number = 10) => {
    dispatch(StagingSlice.actions.setIsFetching(true));

    ENDPOINTS.staging_profile()
      .staging_list({
        page,
        page_size,
        class_type_id: classType?.value,
        year: year,
        quarter: quarter,
        keyword,
        filter_type: filter?.value,
      })
      .then((response: any) => {
        if (response.data.data.clients != null) {
          let staging_list: staging[] = response.data.data.clients.map(
            (staging: any): staging => ({
              id: staging.id,
              class_type: staging.class_type_name,
              cif: staging.cif,
              name: staging.name,
              financial_status: staging.financial_status,
              stage: staging.stage_no,

              year: staging.year,
              quarter: staging.quarter,
            }),
          );

          dispatch(StagingSlice.actions.addStaging(staging_list));
          dispatch(
            StagingSlice.actions.setHasMore(
              page < Number(response.data.data.last_page),
            ),
          );
          //console.log(page !== Number(response.data.data.last_page))
          if (!state.isLoaded) dispatch(StagingSlice.actions.setIsLoaded(true));
        }
      });
  };

  useEffect(() => {
    tableRef.current?.reset();
    dispatch(StagingSlice.actions.reset());
  }, [classType, year, quarter, filter]);

  interface tableDataType {
    [key: string]: { [key: string]: any };
  }
  const generateData: () => tableDataType = () => {
    let data: tableDataType = {};
    state.staging_list.map((staging, index) => {
      if (staging != null) {
        data[staging.id] = {
          actions1: (
            <div className="SimpleCheckbox">
              <input
                type="checkbox"
                value={staging.cif}
                onChange={handleChange}
              />
              {/*{ allchecked ? <div>{allchecked}</div> : ""}*/}
            </div>
          ),

          cif: staging.cif,
          name: staging.name,
          class_type: staging.class_type,
          stage: staging.stage,
          actions: (
            <div className="click">
              <Link
                to={
                  `/search-client?cif=${staging.cif}` +
                  (year ? `&year=${year}` : '') +
                  (quarter ? `&quarter=${quarter}` : '')
                }>
                <i className="icon-info" style={{ color: '#333' }} />
              </Link>
            </div>
          ),
        };
      }
    });

    return data;
  };

  // First fetch
  useEffect(() => {
    if (!state.isLoaded && !state.isFetching) fetchData(1);
  }, []);

  return (
    <>
      {state.isLoaded ? (
        <>
          {state.isLoading ? <WhiteboxLoader /> : ''}
          <form>
            <div className="filters">
              <div className="filter">
                <SelectField
                  placeholder="Filter"
                  options={[
                    { label: 'All', value: 'all' },
                    { label: 'Has stage', value: 'with' },
                    { label: 'No stage', value: 'without' },
                  ]}
                  onChange={(selected: any) => setFilter(selected)}
                  value={filter}
                />
              </div>
              <div className="filter" key="StagingFilter">
                <ClassesMenu
                  isClearable
                  value={classType}
                  onChange={(selected: any) => setClassType(selected)}
                  placeholder="Filter by class type"
                />
              </div>
              <div className="filter">
                <SelectField
                  isClearable
                  defaultValue={year ? { label: year, value: year } : undefined}
                  onChange={(selected: { value: number }) =>
                    setYear(selected?.value)
                  }
                  placeholder={t('year')}
                  options={years}
                />
              </div>
              <div className="filter">
                <SelectField
                  isClearable
                  defaultValue={
                    quarter
                      ? { label: quarter?.toUpperCase(), value: quarter }
                      : undefined
                  }
                  onChange={(selected: { value: 'q1' | 'q2' | 'q3' | 'q4' }) =>
                    setQuarter(selected?.value)
                  }
                  placeholder={t('quarter')}
                  options={[
                    { label: 'Q1', value: 'q1' },
                    { label: 'Q2', value: 'q2' },
                    { label: 'Q3', value: 'q3' },
                    { label: 'Q4', value: 'q4' },
                  ]}
                />
              </div>
            </div>
          </form>
          <TableActionBar
            title={t('staging')}
            search={search}
            showFilter={false}
          />

          {/* Client Stage */}
          {/*<ClientStage isOpen={isOpenEditStage} toggle={() => setIsOpenEditStage(false)} client_id={client.id}  year={selectedYear?.value} quarter={selectedQuarter?.value} class_type={client.class_type_id}  isUpdate={isUpdate} />  */}

          <ClientStage_Groupe
            isOpen={isOpenEditStage}
            toggle={() => setIsOpenEditStage(false)}
            client_id={allchecked}
            year={year}
            quarter={quarter}
            class_type={classType?.value}
            isUpdate={false}
          />

          <div className="search-client-actions">
            <Row>
              <Col md={12}>
                <Row>
                  {/* Client_Stage */}

                  <Col
                    md={6}
                    style={{ position: 'sticky', top: 11, textAlign: 'right' }}
                    className="actions">
                    <button
                      className="button color-gold"
                      onClick={() => {
                        if (classType == undefined)
                          window.alert(
                            'Please you must select input type in the filter',
                          );
                        else setIsOpenEditStage(true);
                        setSelectedYear(selectedYear);
                        setSelectedQuarter(selectedQuarter);
                      }}>
                      {t('Add Same Stage to Selected Clients')}
                    </button>
                    <span className="margin-10" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <DashboardTable
            ref={tableRef}
            header={[
              t('Select'),
              t('cif'),
              t('name'),
              t('class_type'),
              t('stage'),
              'Details',
            ]}
            body={generateData()}
            hasMore={state.hasMore}
            loadMore={fetchData}
          />
        </>
      ) : (
        <div className="center">
          <EllipsisLoader />
        </div>
      )}
    </>
  );
};
