import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import './AddSettings.css';
import {
  Checkbox,
  InputField,
  NumberField,
} from '../../components/FormElements/FormElements';
import { WhiteboxLoader } from '../../components/Loader/Loader';
import './AddNewSettings.css';
import { t } from 'react-multi-lang';
import { Col, Row } from 'react-grid-system';
import { predefinedState } from '../../components/PredefinedMenus/PredefinedMenusSlice';
import { useSelector } from 'react-redux';
import SettingsApi from '../../services/api/settings';
import { getGeneratedNameForNode } from 'typescript';
import { years } from '../../services/hoc/helpers';
import { SelectField } from '../../components/FormElements/FormElements';

interface IProps {
  readonly: boolean;

  isUpdate: boolean;
}
export default (props: IProps) => {
  const predefinedState: predefinedState = useSelector(
    (state: { predefined_menus: predefinedState }) => state.predefined_menus,
  );

  const questionsRef = useRef<HTMLDivElement>(null);
  const saveRef = useRef<HTMLDivElement>(null);

  const [generalFields, setGeneralFields] = useState<any>([]);

  const [documentsFields, setDocumentsFields] = useState<
    { id: number; ccf: number | string; name: string }[]
  >([]);

  const [documentsFields1, setDocumentsFields1] = useState<
    { id: number; score: number | string; name: string }[]
  >([]);

  const [documentsFields2, setDocumentsFields2] = useState<
    { id: number; score: number | string; name: string }[]
  >([]);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [quarter, setQuarter] = useState<string>('q1');
  // PD & LGD
  const [preDefined, setPreDefined] = useState<any>({});
  const [lgdFields, setLgdFields] = useState<any>({});
  const [lgdColumns, setLgdColumns] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [category, setCategory] = useState<any>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [financialStatus, setFinancialStatus] = useState<any>();
  const [step, setStep] = useState(0);
  const [settingsName, setSettingsName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (category && financialStatus) {
      setIsLoaded(false);
    }
  }, [category, financialStatus]);

  const ENDPOINTS = new SettingsApi();

  useEffect(() => {
    ENDPOINTS.settings()
      .general()
      .then((res: any) => {
        setGeneralFields(res.data?.data);
        setIsLoading(false);
      });
    ENDPOINTS.settings()
      .documents({ page: 1, page_size: 1000 })
      .then((res: any) => {
        setDocumentsFields(res.data?.data?.document_types);
        setIsLoading(false);
      });

    ENDPOINTS.settings()
      .documents1({ page: 1, page_size: 1000 })
      .then((res: any) => {
        setDocumentsFields1(res.data?.data?.score_ranges);
        setIsLoading(false);
      });

    ENDPOINTS.settings()
      .documents2({ page: 1, page_size: 1000 })
      .then((res: any) => {
        setDocumentsFields2(res.data?.data?.retial_score_ranges);
        setIsLoading(false);
      });

    ENDPOINTS.settings()
      .predefined({ page: 1, page_size: 1000 })
      .then((res: any) => {
        let tempPreDefined = { ...preDefined };
        res.data?.data['pre-defined']?.map((item: any) => {
          if (!tempPreDefined[item.class_type_name])
            tempPreDefined[item.class_type_name] = {};
          if (!tempPreDefined[item.class_type_name][item.stage_name])
            tempPreDefined[item.class_type_name][item.stage_name] = [];
          tempPreDefined[item.class_type_name][item.stage_name].push({
            id: item.id,
            grade: item.grade_name,
            pd: item.pd,
            lgd: item.lgd,
            currency_type: item.currency_type,
          });
        });
        setPreDefined(tempPreDefined);
      });
    ENDPOINTS.settings()
      .lgd({ page: 1, page_size: 1000 })
      .then((res: any) => {
        let tempLgd = { ...lgdFields };
        let tempLgdColumns = [...lgdColumns];
        res.data?.data['lgd-guarantee']?.map((item: any) => {
          if (!tempLgd[item.guarantee_name]) tempLgd[item.guarantee_name] = {};
          if (!tempLgd[item.guarantee_name][item.stage_name])
            tempLgd[item.guarantee_name][item.stage_name] = [];
          tempLgd[item.guarantee_name][item.stage_name].push(item);
          if (!tempLgdColumns.includes(item.class_type_name))
            tempLgdColumns.push(item.class_type_name);
        });
        setLgdFields(tempLgd);
        setLgdColumns(tempLgdColumns);
      });
  }, []);
  const back = () => {
    preStep();
  };

  const submit = () => {
    if (!checkAbilityToSubmit()) {
      nextStep();
      return;
    }
    setSubmitting(true);

    var query = {
      setting_name: settingsName,
      year: year,
      quarter: quarter,
      second_general_val: generalFields[0][1].value,
      fourth_general_val: generalFields[0][3].value,
      fifth_general_val: generalFields[0][4].value,
      sixth_general_val: generalFields[0][5].value,
      ccf1: documentsFields[0].ccf,
      ccf2: documentsFields[1].ccf,
      ccf3: documentsFields[2].ccf,
      ccf4: documentsFields[3].ccf,
      ccf5: documentsFields[4].ccf,
      ccf6: documentsFields[5].ccf,
      ccf7: documentsFields[6].ccf,
      ccf8: documentsFields[7].ccf,
      ccf9: documentsFields[8].ccf,

      score1: documentsFields1[0].score,
      score2: documentsFields1[1].score,
      score3: documentsFields1[2].score,
      score4: documentsFields1[3].score,
      score5: documentsFields1[4].score,
      score6: documentsFields1[5].score,
      score7: documentsFields1[6].score,
      score8: documentsFields1[7].score,
      score9: documentsFields1[8].score,
      score10: documentsFields1[9].score,
      score11: documentsFields1[10].score,
      score12: documentsFields1[11].score,
      score13: documentsFields1[12].score,
      score14: documentsFields1[13].score,
      retail_score1: documentsFields2[0].score,
      retail_score2: documentsFields2[1].score,
      retail_score3: documentsFields2[2].score,
      retail_score4: documentsFields2[3].score,
      retail_score5: documentsFields2[4].score,
      retail_score6: documentsFields2[5].score,
      lgd: lgdFields,
      bank: preDefined,
    };
    console.log(query);
    ENDPOINTS.settings()
      .addSettingModels(query)
      .then((data: any) => {
        setSubmitting(false);

        toast('تمت الإضافة بنجاح', {
          progressStyle: { background: 'tomato' },
          className: 'toast',
          autoClose: 1000,

          onClose: function () {
            window.location.reload();
          },
        });
      });

    if (props.isUpdate == false) {
    } else if (props.isUpdate == true) {
    }
  };

  const checkAbilityToSubmit = () => {
    if (step < 5) {
      return false;
    } else {
      return true;
    }
  };
  const nextStep = () => {
    if (step < 5) {
      if (step == 0)
        if (!settingsName || settingsName.trim() === '') {
          setErrorMessage('Settings Name is required.');
          if (questionsRef.current) {
            questionsRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
        } else {
          const field = generalFields[1].find(
            (title: any) => title.name === settingsName,
          );

          if (field) {
            setErrorMessage('This Settings Name Is Already Exist');
            if (questionsRef.current) {
              questionsRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }

          } else {
            const x = step + 1;
            setStep(x);
            setErrorMessage('');
            if (questionsRef.current) {
              questionsRef.current.scrollIntoView({
                behavior: 'auto',
                block: 'start',
              });
            }
          }
        }
      else {
        const x = step + 1;
        setStep(x);
        if (questionsRef.current) {
          questionsRef.current.scrollIntoView({
            behavior: 'auto',
            block: 'start',
          });
        }
      }
    }
  };
  const preStep = () => {
    if (step > 0) {
      const x = step - 1;
      setStep(x);
    }
  };

  return (
    <div
      style={{ minWidth: 700, maxWidth: 900, overflowY: 'auto' }}
      ref={questionsRef}
      className="add-profile">
      {submitting && <WhiteboxLoader />}
      <h2 style={{ margin: '0 0 20px' }}>
        {props.readonly ? t('show_rate') : t('Add New Settings')}
      </h2>
      <form onSubmit={(e) => e.preventDefault()}></form>
      {/* General */}
      {step === 0 && (
        <div style={{ maxWidth: 400, marginTop: 40 }}>
          <InputField
            value={settingsName}
            type="text"
            placeholder={'Settings Name'}
            style={{ background: '#FFF', border: '1px solid #DDD' }}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setSettingsName(e.currentTarget.value);
            }}
          />
          {errorMessage && (
            <div
              style={{
                background: 'red',
                color: 'white',
                padding: '5px',
                borderRadius: '20px',
                margin: '10px',
                textAlign: 'center',
              }}>
              {errorMessage}
            </div>
          )}

          <SelectField
            isClearable
            defaultValue={year ? { label: year, value: year } : undefined}
            onChange={(selected: { value: number }) => setYear(selected?.value)}
            placeholder={t('year')}
            options={years}
          />
          <SelectField
            isClearable
            defaultValue={
              quarter
                ? { label: quarter?.toUpperCase(), value: quarter }
                : undefined
            }
            onChange={(selected: { value: 'q1' | 'q2' | 'q3' | 'q4' }) =>
              setQuarter(selected?.value)
            }
            placeholder={t('quarter')}
            options={[
              { label: 'Q1', value: 'q1' },
              { label: 'Q2', value: 'q2' },
              { label: 'Q3', value: 'q3' },
              { label: 'Q4', value: 'q4' },
            ]}
          />
          <h3>General</h3>
          {generalFields[0]?.map((field: any, index: any) => (
            <>
              {field.type == 1 ? (
                <Checkbox
                  checked={field.value === 1}
                  label={field.description.replace(' (0: false, 1:true)', '')}
                  onChange={(e: any) => {
                    setGeneralFields((prev: any) => {
                      const temp = [...prev];
                      temp[0][index].value = e.target.checked ? 1 : 0;
                      return temp;
                    });
                  }}
                />
              ) : generalFields[0].find((item: any) => item.id === 3)?.value ===
                  0 && [4, 5, 6].includes(field.id) ? (
                ''
              ) : (
                <NumberField
                  label={field.description}
                  value={field.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setGeneralFields((prev: any) => {
                      const temp = [...prev];
                      temp[0][index].value = e.target.value;
                      return temp;
                    });
                  }}
                />
              )}
            </>
          ))}
          <br />
          <br />

          <br />
          <br />
        </div>
      )}
      {/* Documents */}
      {step === 1 && (
        <div style={{ maxWidth: 400, marginTop: 40 }}>
          <h3>Credit Conversion Factors(CCF)</h3>
          {documentsFields?.map((field, index) => (
            <NumberField
              label={field.name}
              value={field.ccf}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDocumentsFields((prev) => {
                  const temp = [...prev];
                  temp[index].ccf = e.target.value;
                  return temp;
                });
              }}
            />
          ))}
          <br />
          <br />

          <br />
          <br />
        </div>
      )}
      {/* PD & LGD */}
      {step === 2 && (
        <>
          <h3>Guarantee</h3>
          {
            <table className="table">
              <thead>
                <tr>
                  <th>Stage</th>
                  {lgdColumns.map((col) => (
                    <th>{col}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.keys(lgdFields).map((key) => (
                  <>
                    <tr>
                      <td
                        style={{
                          paddingLeft: 20,
                          fontWeight: 'bold',
                          fontSize: 18,
                        }}
                        colSpan={lgdColumns.length + 1}>
                        {key}
                      </td>
                    </tr>
                    {Object.keys(lgdFields[key]).map((stageKey) => (
                      <tr>
                        <td style={{ paddingLeft: 20 }}>{stageKey}</td>
                        {lgdColumns.map((col) => (
                          <td style={{ padding: '0 10px' }}>
                            {lgdFields[key][stageKey].find(
                              (item: any) => item.class_type_name === col,
                            ) && (
                              <NumberField
                                label="Value"
                                value={
                                  lgdFields[key][stageKey].find(
                                    (item: any) => item.class_type_name === col,
                                  ).value
                                }
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #DDD',
                                }}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  const temp = { ...lgdFields };
                                  temp[key][stageKey][
                                    lgdFields[key][stageKey].findIndex(
                                      (item: any) =>
                                        item.class_type_name === col,
                                    )
                                  ].value = +e.target.value;
                                  setLgdFields(temp);
                                }}
                              />
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                ))}
              </tbody>
            </table>
          }

          <br />
          <br />
        </>
      )}
      {/* Banks */}
      {step === 3 && (
        <table className="table">
          <h3>Banks</h3>
          <hr />

          {Object.keys(preDefined).map((key) => (
            <>
              <h3>{key}</h3>
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ minWidth: 100 }}>Grade</th>
                    {preDefined[key][Object.keys(preDefined[key])[0]]?.map(
                      (item: any) => (
                        <th>
                          {key === 'Central Bank'
                            ? item.currency_type
                            : item.grade}
                        </th>
                      ),
                    )}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(preDefined[key]).map((stageKey) => (
                    <tr>
                      <td style={{ textAlign: 'center' }}>{stageKey}</td>
                      {preDefined[key][stageKey]?.map(
                        (item: any, index: any) => (
                          <td style={{ padding: '0 10px' }}>
                            {item.pd !== null && (
                              <NumberField
                                label="PD"
                                value={item.pd === -1 ? undefined : item.pd}
                                style={{
                                  background: '#FFF',
                                  border: '1px solid #DDD',
                                }}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  const temp = { ...preDefined };
                                  temp[key][stageKey][index].pd =
                                    +e.target.value;
                                  setPreDefined(temp);
                                }}
                              />
                            )}
                            <NumberField
                              label="LGD"
                              value={item.lgd == -1 ? undefined : item.lgd}
                              style={{
                                background: '#FFF',
                                border: '1px solid #DDD',
                              }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                const temp = { ...preDefined };
                                temp[key][stageKey][index].lgd =
                                  +e.target.value;
                                setPreDefined(temp);
                              }}
                            />
                          </td>
                        ),
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ))}
        </table>
      )}
      {/* Score Rangs  With FinancialStatus */}
      {step === 4 && (
        <>
          <h3>Ranges With FinancialStatus </h3>

          <div style={{ maxWidth: 400, marginTop: 40 }}>
            {documentsFields1?.map((field, index) => (
              <NumberField
                label={field.name}
                value={field.score}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDocumentsFields1((prev) => {
                    const temp = [...prev];
                    temp[index].score = e.target.value;
                    return temp;
                  });
                }}
              />
            ))}
            <br />
            <br />

            <br />
            <br />
          </div>

          <br />
          <br />
        </>
      )}
      {/* Score Rangs  whithout  FinancialStatus */}
      {step === 5 && (
        <>
          <h3>Ranges whithout FinancialStatus </h3>

          <div style={{ maxWidth: 400, marginTop: 40 }}>
            {documentsFields2?.map((field, index) => (
              <NumberField
                label={field.name}
                value={field.score}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDocumentsFields2((prev) => {
                    const temp = [...prev];
                    temp[index].score = e.target.value;
                    return temp;
                  });
                }}
              />
            ))}
            <br />
            <br />

            <br />
            <br />
          </div>

          <br />
          <br />
        </>
      )}
      <div className="save" ref={saveRef}>
        <button
          className="button bg-gold color-white"
          style={{ margin: '1%' }}
          onClick={back}>
          {step < 1 ? ' ' : 'back'}
        </button>
        <button className="button bg-gold color-white" onClick={submit}>
          {checkAbilityToSubmit() ? t('submit_data') : t('continue')}
        </button>
      </div>
    </div>
  );
};
